import Richtext from "../components/dynamicBlocks/Richtext.vue";
import BlogFeature from "../components/dynamicBlocks/BlogFeature.vue";
import HubspotForm from "../components/dynamicBlocks/HubspotForm.vue";
import Image from "../components/dynamicBlocks/Image.vue";

const componentImports = {
    Richtext,
    BlogFeature,
    HubspotForm,
    Image
};

export default componentImports;
