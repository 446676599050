<template>
  <aside :id="data?.blockId ?? null" :data-index="index" :data-block="data.__component"
    class="relative my-12 rounded-xl shadow p-8 lg:p-12 max-w-2xl mx-auto max-md:mx-4"
    :class="{ 'bg-white': data.style != 'feature', 'bg-black text-white': data.style == 'feature' }">
    <div class="mb-6" v-if="data.title || data.lead">
      <h2 class="text-xl font-medium lg:text-2xl text-balance" v-if="data.title">{{ data.title }}</h2>
      <p class="prose mt-2" :class="{ 'prose-invert': data.style == 'feature' }" v-if="data.lead">{{ data.lead }}</p>
    </div>
    <div class="hbspt-form sticky top-24 min-h-40 w-full" :id="'hbspt-form-' + data.hubspotFormId + '-' + index">
      <div class="absolute rounded-lg h-full w-full z-0 top-0 left-0 flex justify-center items-center"
        :class="{ 'bg-light-gray': data.style != 'feature', 'bg-white/10': data.style == 'feature' }">

        <div class="flex items-center justify-center relative h-full w-full text-center"
          :class="{ 'text-black/50': data.style != 'feature', 'text-white/50': data.style == 'feature' }">
          <svg class="animate-spin -ml-1 mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          <span class="text-sm">Initializing ...</span>
        </div>
      </div>
    </div>
  </aside>
</template>


<script setup lang="ts">


import { onMounted } from 'vue';

const props = defineProps<{
  data: any,
  index: any
}>();


onMounted(() => {
  if (process.client) {
    loadHubspotForm(props.data.hubspotFormId, props.index);
  }
});

function loadHubspotForm(id: string, index: number) { // Accept id as a parameter
  window.hsFormsOnReady = window.hsFormsOnReady || [];
  window.hsFormsOnReady.push(() => {
    hbspt.forms.create({
      portalId: "26139321",
      formId: id,
      target: '#hbspt-form-' + id + '-' + index,
      region: "eu1",
    });
  });

}

</script>